<template>
  <div class="edit">
    <edudash-input
      v-model="name"
      rules="required"
      prepend-icon="person"
      :name="$t('components.labels.name')"
      :label="$t('components.labels.name')"
    />

    <edudash-input
      v-model="email"
      rules="required|email"
      prepend-icon="mail_outline"
      :name="$t('components.labels.email')"
      :label="$t('components.labels.email')"
    />

    <validation-observer>
      <edudash-input
        v-model="password"
        prepend-icon="https"
        :name="$t('views.new_password.password')"
        :label="$t('views.new_password.password_label')"
        rules="required|password:@confirmation"
        type="password"
        class="mb-4"
      />
      <edudash-input
        :ref="$t('views.new_password.repeat_password')"
        v-model="passwordConfirmation"
        vid="confirmation"
        prepend-icon="https"
        :label="$t('views.new_password.repeat_password')"
        :name="$t('views.new_password.repeat_password')"
        type="password"
        rules="required"
        class="mb-4"
      />
    </validation-observer>

    <edudash-button
      class="edudash-button mt-4"
      :full-width="false"
      :text="$t('components.labels.save')"
      @click="saveInformations"
    />
  </div>
</template>

<script>

import EdudashButton from '@/components/forms/EdudashButton.vue';
import EdudashInput from '@/components/forms/EdudashInput.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Edit',
  components: {
    EdudashButton,
    EdudashInput,
  },
  data() {
    return {
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    };
  },
  computed: {
    ...mapGetters([
      'currentTeacher',
    ]),
  },
  watch: {
    currentTeacher(value) {
      if (value) {
        this.addSuccessMessage(this.$t('views.profile.success'));
        this.$router.push({
          name: 'profile',
        });
      }
    },
  },
  mounted() {
    this.changeToolbarText(this.$t('components.labels.edit_information'));
    this.name = this.currentTeacher.name;
    this.email = this.currentTeacher.email;
  },
  methods: {
    ...mapActions([
      'changeToolbarText',
      'update',
      'addSuccessMessage',
    ]),
    saveInformations() {
      this.update({
        name: this.name,
        email: this.email,
        password: this.password === '' ? null : this.password,
        password_confirmation: this.passwordConfirmation === '' ? null : this.passwordConfirmation,
      });
    },
  },
};
</script>

<style lang="scss">
.edit {
  padding: 24px 16px;

  .edudash-button {
    width: 164px;
  }

  .v-icon.v-icon {
    color: $text-color-secondary;
  }

  .v-label {
    color: $text-color-secondary;
  }
}
</style>
