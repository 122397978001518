<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="rules"
    :name="name"
    :vid="vid"
  >
    <v-text-field
      v-model="content"
      v-mask="mask"
      class="edudash-text-field"
      :prepend-inner-icon="prependIcon"
      :label="label"
      :error-messages="errors"
      :type="type"
      :change="emitChange()"
    />
  </ValidationProvider>
</template>
<script>
import { VueMaskDirective } from 'v-mask';

export default {
  name: 'EdudashInput',
  directives: { mask: VueMaskDirective },
  model: {
    prop: 'text',
    event: 'change',
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    vid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      content: this.text,
    };
  },
  watch: {
    text(value) {
      this.content = value;
    },
  },
  methods: {
    emitChange() {
      this.$emit('change', this.content);
    },
  },
};
</script>
<style lang="scss" scoped>
  .edudash-text-field.v-input ::v-deep {
    @include font-monteserrat-semi-bold;
    margin: 0 0 8px;
    padding-top: 0;
    color: $text-color-secondary;
    line-height: 16px;
    font-size: $font-size-extra-small;
    .v-text-field__slot {
      height: 30px;
    }
    .v-label {
      font-size: $font-size-extra-small;
    }
  }
</style>
