<template>
  <div class="schools">
    <v-list-item class="pa-0">
      <v-list-item-avatar>
        <v-icon>
          home
        </v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-html="school.name" />
        <v-list-item-subtitle
          v-for="(school_class, index) in school.school_classes"
          :key="index"
        >
          <span v-if="school_class.active">
            - {{ school_class.name }}
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'SchoolsListItem',
  props: {
    school: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.schools {

  .theme--light.v-list-item .v-list-item__subtitle {
    color: $text-color-secondary;
  }

  .v-list-item__avatar:first-child {
    margin-right: 0;
  }

  .v-list-item__title {
    @include font-open-sans-bold;
    font-size: $font-size-extra-small;
    line-height: 16px;
    white-space: initial;
    color: $text-color-primary;
  }

  .v-list-item__subtitle {
    @include font-open-sans-regular;
    font-size: $font-size-extra-small;
    line-height: 16px;
    text-transform: capitalize;
    white-space: initial;
    display: contents;
    width: fit-content;
  }
}
</style>
