<template>
  <div class="edudash-label">
    <v-icon>
      {{ icon }}
    </v-icon>
    <span class="label">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'EdudashLabel',
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.edudash-label {
  .label {
    @include font-monteserrat-semi-bold;
    color: $text-color-secondary;
    font-size: $font-size-extra-small;
    line-height: 16px;
  }

  .v-icon.v-icon {
    color: $text-color-secondary;
  }

}
</style>
