<template>
  <div class="profile">
    <edudash-label
      :text="userName"
      icon="person"
      class="label"
    />
    <edudash-label
      :text="email"
      icon="mail_outline"
      class="label"
    />
    <edudash-label
      :text="cpf"
      icon="description"
      class="label"
    />
    <edudash-label
      :text="birthday"
      icon="date_range"
      class="label"
    />

    <div
      class="edit-profile"
      @click="goToEditProfile()"
    >
      {{ $t('components.labels.edit_information') }}
    </div>

    <div class="schools-list">
      {{ $t('views.profile.school_classes') }}

      <template
        v-for="(school, index) in schools"
      >
        <schools-list-item
          :key="index"
          :school="school"
        />
      </template>
    </div>

    <div
      class="edit-profile"
      @click="logoutTeacher"
    >
      {{ $t('views.profile.logout') }}
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import EdudashLabel from '@/components/forms/EdudashLabel.vue';
import SchoolsListItem from '@/components/lists/Schools.vue';

export default {
  name: 'Profile',
  components: {
    EdudashLabel,
    SchoolsListItem,
  },
  data() {
    return {
      schools: [],
    };
  },
  computed: {
    ...mapGetters([
      'currentTeacher',
      'getTeacherSchools',
    ]),
    userName() {
      return this.currentTeacher.name;
    },
    email() {
      return this.currentTeacher.email;
    },
    cpf() {
      return this.currentTeacher.cpf.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/g,
        '$1.$2.$3-$4',
      );
    },
    birthday() {
      return this.currentTeacher.birth;
    },
  },
  watch: {
    getTeacherSchools(value) {
      if (value) {
        this.schools = value;
      }
    },
  },
  mounted() {
    this.changeToolbarText(this.$t('views.profile.profile'));
    this.findTeacherSchools();
  },
  methods: {
    ...mapActions([
      'changeToolbarText',
      'logout',
      'findTeacherSchools',
    ]),
    goToEditProfile() {
      this.$router.push({
        name: 'editProfile',
        params: {
          id: this.currentTeacher.id,
        },
      });
    },
    logoutTeacher() {
      const jwtToken = this.currentTeacher.jwt_token;
      this.$router.push({ name: 'logout', params: { jwt_token: jwtToken } });
      this.logout();
    },
  },
};
</script>

<style lang="scss">
.profile {
  padding: 16px;

  .label {
    margin-bottom: 16px;
  }

  .edit-profile {
    @include font-open-sans-bold;
    font-size: $font-size-extra-small;
    color: $secondary-button-color;
    line-height: 16px;
    margin: 24px 0;
    width: 100%;
  }

  .schools-list {
    @include font-monteserrat-regular;
    font-size: $font-size-extra-small;
    line-height: 16px;
    color: $text-color-secondary;
  }
}
</style>
