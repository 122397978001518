<template>
  <div>
    <v-btn
      :block="fullWidth"
      :disabled="disabled"
      :tag="tag"
      :href="href"
      rounded
      class="edudash-button"
      color="primary_button"
      height="44"
      @click="emitClick"
    >
      <span class="button-text">
        {{ buttonText }}
      </span>
    </v-btn>
  </div>
</template>

<script>

export default {
  name: 'EdudashButton',
  props: {
    text: {
      type: String,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'button',
    },
    download: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
  },
  computed: {
    buttonText() {
      return this.text ? this.text : this.$t('components.forms.edudash_button.default_text');
    },
  },
  methods: {
    emitClick() {
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
.edudash-button {
  @include font-open-sans-bold;
  font-size: $font-size-extra-small;
  letter-spacing: initial;
  text-transform: capitalize;
  .button-text {
    color: $text-color-white;
  }

  .v-btn--contained {
    box-shadow: 0 3px 6px $border-color;
  }
}
</style>
